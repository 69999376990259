@tailwind base;
@tailwind components;
@tailwind utilities;

/* For pdf */
.react-pdf__Page {
    margin-top: 10px;
}

.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Page__annotations.annotationLayer {
    display: none;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
}

/* For all pages */
.all-page-container {
    height: 100%;
    overflow: auto;
}
